<script lang="ts" setup>
  import { ref, watchEffect } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import { useWindowSize } from '@vueuse/core'

  import { getBestPracticesReportData } from "@/services/Api"
  import { getSiteByCmiId, getSitesForProject } from "@/services/Site"
  import { getProjectByCmiId, getProjects } from "@/services/Project"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"
  import { ISite } from "@/db"

  import PageLoading from "@/components/PageLoading.vue"
  import ReportMenu from "@/components/assessments/ReportMenu.vue"
  import SiteHeader from "@/components/sites/SiteHeader.vue"

  const { $gettext } = gettext
  const route = useRoute()
  const router = useRouter()
  const siteCmiId = parseInt(route.params.siteCmiId as string)
  const projectCmiId = parseInt(route.params.projectCmiId as string)
  const allColsMinWidth = 625 // Breakpoint. Should correspond with $tablet defined in _main.css

  const { width } = useWindowSize()
  const data = ref(null)
  const pageReady = ref(false)
  // Machinery to only show part of the columns if the viewport is too narrow.
  const showAllChildrenCol = ref(true)
  const showMultiAssessmentChildrenCol = ref(width.value > allColsMinWidth)
  const site = ref(null as ISite)

  watchEffect(() => {
    // The minor downside to this approach is if they're on desktop and
    // resizing at narrow viewport widths, they'll keep reverting to showing
    // All children if the multi had been selected. Don't really care.
    showAllChildrenCol.value = true
    showMultiAssessmentChildrenCol.value = width.value > allColsMinWidth
  })

  // Most of the rows of stats have three elements, but a few have only
  // one. Pad those so the rows have a consistent number of elements.
  function padStats(stats: Array<object>) {
    while (stats.length < 3) {
      stats.push(null)
    }
    return stats
  }

  async function getData() {
    let project
    if (siteCmiId) {
      site.value = await getSiteByCmiId(siteCmiId)
      if (site.value) {
        setCurrentViewContext(site.value, "site")
      }
      else {
        router.push({ name: "HomeRedirect" })
      }
    }
    else if (projectCmiId) {
      project = await getProjectByCmiId(projectCmiId)
      if (project) {
        setCurrentViewContext(project, "project")
      }
      else {
        router.push({ name: "HomeRedirect" })
      }
    }
    data.value = await getBestPracticesReportData({ siteCmiId, projectCmiId })
      .catch(error => {
        // FORBIDDEN is definitely a permissions issue; NOT_FOUND could well be one as well
        // (url fishing). Just redirect them silently.
        if (error.name === "NOT_FOUND" || error.name === "FORBIDDEN") {
          router.push({ name: "HomeRedirect" })
        }
        else {
          throw error
        }
      })
    pageReady.value = true
  }

  getData()
</script>
<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{
        label: $gettext('Summary reports'),
        urlParams: { name: 'SiteLevelDemographicReport', params: { siteCmiId } },
      }"
    />
    <div class="columns">
      <div class="column">
        <div
          v-if="siteCmiId"
          class="super-title"
        >
          {{ $gettext("Site Reports") }}
        </div>
        <div
          v-else-if="projectCmiId"
          class="super-title"
        >
          {{ $gettext("Project Reports") }}
        </div>
        <div
          v-else
          class="super-title"
        >
          App-level Reports
        </div>
        <h1 class="title">
          {{ $gettext("Best Practices Report") }}
        </h1>
      </div>
      <div class="column is-narrow has-text-right">
        <ReportMenu
          selected="BestPracticesReport"
          :params="route.params"
        />
      </div>
    </div>
    <PageLoading v-if="!pageReady" />
    <template v-else>
      <div class="message is-danger block">
        <p class="message-body m-0">
          {{ $gettext("Note: these data only show broad trends. Additional analysis may be required.") }}
        </p>
      </div>
      <section class="block">
        <div class="table-container">
          <table class="table is-striped">
            <caption>
              {{ $gettext("Table caption TK") }}
              <!-- Only show the toggles if only one of the settings is true -->
              <template v-if="showMultiAssessmentChildrenCol !== showAllChildrenCol">
                <a
                  v-if="showMultiAssessmentChildrenCol"
                  class="text-link"
                  @click="() => {showMultiAssessmentChildrenCol = false; showAllChildrenCol = true }"
                >
                  {{ $gettext("Show all children") }}
                </a>
                <a
                  v-if="showAllChildrenCol"
                  class="text-link"
                  @click="() => {showAllChildrenCol = false; showMultiAssessmentChildrenCol = true }"
                >
                  {{ $gettext("Show children with multiple assessments") }}
                </a>
              </template>
            </caption>
            <thead>
              <tr class="meta-headers">
                <td />
                <th v-show="showAllChildrenCol">
                  {{ $gettext("All Children") }}
                </th>
                <th
                  v-show="showMultiAssessmentChildrenCol"
                  colspan="3"
                >
                  {{ $gettext("Children with 2+ assessments") }}
                </th>
              </tr>
              <tr>
                <td />
                <th v-show="showAllChildrenCol">
                  {{ $gettext("Baseline") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Baseline") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Recent") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Change") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  class="muted"
                  colspan="5"
                >
                  {{ $gettext("Higher percentages are preferable") }}
                </th>
              </tr>
              <template
                v-for="(row, id) in data"
                :key="id"
              >
                <tr v-if="row.code === 'ZVF'">
                  <th
                    class="muted"
                    colspan="5"
                  >
                    {{ $gettext("Lower percentages are preferable") }}
                  </th>
                </tr>
                <tr>
                  <th>
                    {{ row.label }}
                    <span
                      v-if="row.code"
                      class="muted code"
                    >
                      {{ row.code }}
                    </span>
                  </th>
                  <template
                    v-for="(stat, index) in padStats(row.stats)"
                    :key="index"
                  >
                    <td
                      v-show="(showAllChildrenCol && index === 0) || (showMultiAssessmentChildrenCol && index)"
                      class="stat-cell "
                    >
                      <template v-if="stat">
                        <div class="pct">
                          {{ stat.pct }}
                        </div>
                        <div class="detail">
                          {{ stat.num.toLocaleString() }}/{{ stat.den.toLocaleString() }}
                        </div>
                      </template>
                    </td>
                  </template>
                  <td
                    v-show="showMultiAssessmentChildrenCol"
                    class="stat-cell"
                  >
                    {{ row.change }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="muted">
                  {{ $gettext("Responsive feeding is not an IYCF indicator.") }}
                  {{ $gettext("Only baseline prevalence of exclusive breastfeeding is included.") }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
      <section class="block">
        <h2>
          {{ $gettext("About this table") }}
        </h2>
        <ul class="list">
          <li>
            {{ $gettext("IYCF codes are shown where applicable.") }}
            {{ $gettext('"IYCF" refers to UNICEF/WHO\'s Infant and Young Child Feeding.') }}
            <a
              class="text-link"
              target="_blank"
              href="https://data.unicef.org/topic/nutrition/infant-and-young-child-feeding/"
            >
              {{ $gettext("Visit UNICEF's IYCF website.") }}
            </a>
          </li>
          <li>
            {{ $gettext('"Baseline" columns include only the first assessment performed for each child.') }}
          </li>
          <li>
            {{ $gettext('The "Recent" column includes the last assessment performed for each child.') }}
          </li>
          <li>
            {{ $gettext('The first "Baseline" column includes data from all children for whom at least one assessment was performed.') }}
          </li>
          <li>
            {{ $gettext('The remaining columns include data only from children for whom at least two assessments have been performed.') }}
          </li>
        </ul>
      </section>
    </template>
  </article>
</template>
<style lang="scss" scoped>
  .table {
    border: 1px solid rgb(128, 128, 128);
    border-radius: 10px;
    border-collapse: separate;
    tbody th {
      padding-right: 0;
    }
  }
  th.muted {
    text-align: center;
    font-weight: normal;
    color: rgb(128, 128, 128) !important;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  @media (max-width: 400px) {
    .table thead th {
      // Contortions to fit the table on a phone width.
      font-size: 80%;
    }
  }
  table td.stat-cell .pct {
    color: #154262;
  }
  .table.table-updating tbody td,
  table.table.table-updating td.stat-cell .pct,
  table.table.table-updating td.stat-cell .detail {
    color: rgb(128, 128, 128) !important;
  }
  caption .text-link {
    display: block;
    font-size: 1rem;
  }
  span.muted {
    font-weight: normal;
    color: rgb(128, 128, 128);
    font-size: 0.85rem;
    font-family: monospace;
  }
</style>
